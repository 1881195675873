@include spacingUtils();
@include flexUtils();


html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}
