// Screens
//big : 1170
//medium : 992
//small : 767
//xsmall : 575

:root {
  --color-dark: #171717;
  --color-white: #ffffff;
  --color-deep-green: #009435;
  --color-active: #2684ff;
  --color-active-light: #e7f0fe;
  --color-light-green: #2eb4b3;
  --color-green: #57ab27;
  --color-orange: #f9b200;
  --color-deep-lemon: #fdc923;
  --color-grey-extra-light: #f7f7f7;
  --color-grey-semi-light: #d7d7d7;
  --color-grey-light: #e7e7e7;
  --color-grey: #979797;
  --color-grey-dark: #575757;
  --color-error: #ff0a50;
  --color-orange-dark: #ff5e1e;

  --icon-xxs: 0.75rem;
  --icon-xs: 1rem;
  --icon-sm: 1.25rem;
  --icon-md: 1.5rem;
  --icon-lg: 2.25rem;
  --icon-xl: 4rem;

  --space-header-height: 70px;
  --space-button-height: 2.8rem;
  --space-main-height: 48px;
  --space-lg-height: 4.5rem;

  --space-xxs: 0.25rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-base: 1rem;
  --space-md: 1.25rem;
  --space-lg: 2rem;
  --space-xl: 3.125rem;
  --space-xxl: 6rem;
  --space-xxxl: 9rem;

  --lineheight-xs: 0.9rem;
  --lineheight-sm: 1rem;
  --lineheight-base: 1.125rem;
  --lineheight-md: 1.35rem;
  --lineheight-lg: 1.8125rem;
  --lineheight-xl: 2.5rem;

  --font-light: 300;
  --font-base: 400;
  --font-medium: 500;
  --font-bold: 700;
  --font-ultra: 900;

  --rounded-xxs: 0.4rem;
  --rounded-xs: 0.625rem;
  --rounded-sm: 1rem;
  --rounded-base: 2rem;
  --rounded-circle: 100%;

  --shadow-sm: 0px 0px 4px 0px hsla(0, 0%, 0%, 0.25);
  --shadow-lg: 0px 3px 12px 2px hsla(223, 50%, 88%, 0.56);

  --text-root-size: 16px;
  --text-size-pxxs: 0.5rem;
  --text-size-pxs: 0.75rem;
  --text-size-ps: 0.9375rem;
  --text-size-normal: 1rem;
  --text-size-p: 1.125rem;
  --text-size-h1: 2.125rem;
  --text-size-h2: 1.875rem;
  --text-size-h3: 1.5rem;
  --text-size-h4: 1.25rem;
  --text-size-h5: 1.125rem;
  --text-size-h6: 1rem;
  --text-size-lg: 2.125rem;
  --text-size-xl: 3.125rem;
}
