@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  --wrapper-size: 80px;
  --border-size: 10px;

  border: var(--border-size) solid var(--color-grey-light);
  border-top: var(--border-size) solid var(--color-light-green);
  border-radius: 50%;
  width: var(--wrapper-size);
  height: var(--wrapper-size);
  animation: spin 1s linear infinite;

  &FixedCenter {
    position: fixed;
    inset: 0;
  }

  &Small {
    --wrapper-size: var(--space-button-height);
    --border-size: 5px;
  }

  &XSmall {
    --wrapper-size: 1.75rem;
    --border-size: 3px;
  }
}

.loader {
  svg {
    height: 50px;
    width: 50px;
  }

  circle {
    margin: 0;
    fill: none;
    stroke: var(--color-orange);
    stroke-width: 4px;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}
