$paragraph-sizes: (
  size-xxsmall: var(--text-size-pxxs),
  size-xsmall: var(--text-size-pxs),
  size-small: var(--text-size-ps),
  size-base: var(--text-size-p),
  size-normal: var(--text-size-base),
  size-medium: var(--text-size-h3),
  size-lg: var(--text-size-lg),
  size-xlarge: var(--text-size-xl),
);

$font-weights: (
  weight-light: var(--font-light),
  weight-base: var(--font-base),
  weight-medium: var(--font-medium),
  weight-bold: var(--font-bold),
  weight-ultra: var(--font-ultra),
);

$text-sizes: (
  'a': var(--text-size-p),
  'span': var(--text-size-p),
  'button': var(--text-size-p),
  'label': var(--text-size-pxs),
  'p': var(--text-size-p),
  'h1': var(--text-size-h1),
  'h2': var(--text-size-h2),
  'h3': var(--text-size-h3),
  'h4': var(--text-size-h4),
  'h5': var(--text-size-h5),
  'h6': var(--text-size-h6),
);

$lineheights: (
  'lineheight-xs': var(--lineheight-xs),
  'lineheight-sm': var(--lineheight-sm),
  'lineheight-base': var(--lineheight-base),
  'lineheight-md': var(--lineheight-md),
  'lineheight-lg': var(--lineheight-lg),
  'lineheight-xl': var(--lineheight-xl),
);

$text-colors: (
  'white': var(--color-white),
  'black': var(--color-black),
  'dark': var(--color-dark),
  'dark'-green: var(--color-green),
  'red': var(--color-error),
  'active': var(--color-active),
  'orange': var(--color-orange-dark),
  'green': var(--color-light-green),
  'grey': var(--color-grey),
  'disabled': var(--color-grey-disabled),
);

@each $tag, $text-size-value in $text-sizes {
  .#{$tag} {
    font-size: $text-size-value;

    @if $tag == 'button' or $tag == 'a' {
      text-decoration: underline;
      text-underline-offset: var(--space-xxs);
    }
  }
}

@each $text-color-key, $text-color-value in $text-colors {
  .#{$text-color-key} {
    color: $text-color-value;
  }
}

@each $font-weight-key, $font-weight-value in $font-weights {
  .#{$font-weight-key} {
    font-weight: $font-weight-value;
  }
}

@each $lineheight-key, $lineheight-value in $lineheights {
  .#{$lineheight-key} {
    line-height: $lineheight-value;
  }
}

@each $paragraph-size-key, $paragraph-size-value in $paragraph-sizes {
  .#{$paragraph-size-key} {
    font-size: $paragraph-size-value;
  }
}

.centered {
  text-align: center;
}

.caps {
  text-transform: uppercase;
}

.fluid {
  width: 100%;
}

.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.italic {
  font-style: italic;
}
