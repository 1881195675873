// Spacing Utils classes
@mixin spacingUtils() {
  $sides: (
    't': top,
    'r': right,
    'b': bottom,
    'l': left
  );

  $scales: (
    'auto': auto,
    '0': 0,
    'xxs': var(--space-xxs),
    'xs': var(--space-xs),
    'sm': var(--space-sm),
    'base': var(--space-base),
    'md': var(--space-md),
    'lg': var(--space-lg),
    'xl': var(--space-xl),
    'xxl': var(--space-xxl),
  );

  @each $scale-prop, $scale-value in $scales {
    .m-#{$scale-prop} {
      margin: $scale-value !important;
    }

    .mx-#{$scale-prop} {
      margin-left: $scale-value !important;
      margin-right: $scale-value !important;
    }

    .my-#{$scale-prop} {
      margin-top: $scale-value !important;
      margin-bottom: $scale-value !important;
    }

    .p-#{$scale-prop} {
      padding: $scale-value !important;
    }

    .px-#{$scale-prop} {
      padding-left: $scale-value !important;
      padding-right: $scale-value !important;
    }

    .py-#{$scale-prop} {
      padding-top: $scale-value !important;
      padding-bottom: $scale-value !important;
    }
  }


  @each $side-prop, $side-value in $sides {
    @each $scale-prop, $scale-value in $scales {
      .m#{$side-prop}-#{$scale-prop} {
        margin-#{$side-value}: $scale-value !important;
      }

      .p#{$side-prop}-#{$scale-prop} {
        padding-#{$side-value}: $scale-value !important;
      }
    }
  }
}


// Flexbox utils classes
@mixin flexUtils() {
  $flexings: (
    'center': center,
    'start': flex-start,
    'end': flex-end,
    'normal': normal,
    'baseline': baseline,
    'around': space-around,
    'between': space-between
  );

  $alignments: (
    'justify': justify-content,
    'content': align-content,
    'align': align-items,
    'align-self': align-self,
    'justify-self': justify-self
  );

  $directions: (
    'flex-row': row,
    'flex-row-reverse': row-reverse,
    'flex-col': column,
    'flex-col-reverse': column-reverse
  );

  $wraps: (
    'wrap': wrap,
    'nowrap': nowrap,
    'wrap-reverse': wrap-reverse
  );

  @each $flex-prop, $flex-value in $flexings {
    @each $alignement-prop, $alignement-value in $alignments {
      .#{$alignement-prop}-#{$flex-prop} {
        #{$alignement-value}: $flex-value;
      }
    }
  }

  @each $direction-prop, $direction-value in $directions {
    .#{$direction-prop} {
      flex-direction: #{$direction-value};
    }
  }

  @each $wrap-prop, $wrap-value in $wraps {
    .#{$wrap-prop} {
      flex-wrap: $wrap-value;
    }
  }

  .flex {
    display: flex;
  }
}
