.wrapper {
  display: grid;
  flex-direction: column;
  min-height: 100%;
  grid-template-rows: min-content 10fr 2%;
  .content {
    margin-top: var(--space-lg);
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
  }
  .footer {
    display: flex;
    justify-self: end;
  }
}
